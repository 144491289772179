<template>
    <div class="history-data">
        <div class="history-data-header">
            <a-form layout="inline" :model="searchModel" style="margin-bottom: 20px;">
                <a-form-item label="选择测点">
                    <a-select v-model:value="searchModel.attrCode" style="min-width: 180px" mode="multiple"
                        :max-tag-count="1" allowClear @change="changeAttrCode">
                        <a-select-option :value="item.attrCode" v-for="item in points" :key="item.attrCode">{{
                item.name
            }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="查询类型">
                    <a-select v-model:value="searchModel.periodType" style="min-width: 180px" allowClear
                        :options="PeriodOptions" @change="changePeriod">
                    </a-select>
                </a-form-item>
                <a-form-item label="查询时间">
                    <a-range-picker style="width: 330px" :value="searchModel.times" :disabledDate="disabledDate"
                        :show-time="{
                defaultValue: [
                    dayjs('00:00:00', 'HH:mm:ss'),
                    dayjs('23:59:59', 'HH:mm:ss'),
                ],
            }" valueFormat="YYYY-MM-DD HH:mm:ss" @change="onChangeTimes" @openChange="onOpenChange"
                        @calendarChange="onCalendarChange" />
                </a-form-item>
            </a-form>
            <div>
                <a-button type="primary" @click="handleSearch" style="margin-right: 8px;">查询</a-button>
                <a-button @click="handleExport" :loading="exportLoading">导出</a-button>
            </div>
        </div>

        <a-table style="height: calc(100vh - 300px);overflow-y: auto;" :columns="columns" :data-source="dataSource"
            size="small" :pagination="false">
            <template #headerCell="{ column }">
                <template v-if="column.numberFlag">
                    <span style="vertical-align: middle;">
                        {{ column.name }}
                        <svg-icon name="chart" class="icon-tip" @click="showModal(column)"
                            style="cursor: pointer;vertical-align: middle;"></svg-icon>
                    </span>
                </template>
                <template v-else>
                    <span>{{ column.name }}</span>
                </template>
            </template>
        </a-table>
        <a-pagination class="atBottom" style="text-align: right" show-size-changer :page-size-options="pageSizeOptions"
            v-model:current="paginationProps.current" v-model:page-size="paginationProps.pageSize"
            :total="allData.length" :style="{ marginTop: '20px' }" :show-total="(total) => `共${total}项`"
            @change="pageChange">
        </a-pagination>
        <a-modal v-model:visible="visible" :title="title" :footer="null" width="80%">
            <BaseChart v-if="chartOptions" width="100%" height="350px" :options="chartOptions"></BaseChart>
        </a-modal>
    </div>
</template>
<!-- eslint-disable no-unused-vars -->

<script setup>
import { ref, onMounted, reactive, watch, defineProps, onActivated, nextTick } from "vue";
import _ from "lodash";
import dayjs from "dayjs";
import { downLoadFile } from "@/utils/excel";
import { message } from "ant-design-vue";
import SvgIcon from "@/components/basic/svgIcon/svg-icon.vue";
import { PeriodOptions } from "@/config";
import { apiDeviceHistoryData } from "@/api/IoT/device.js";
import { useRoute } from "vue-router";

const route = useRoute();
onMounted(() => {
    initPoints()
});
const props = defineProps(['activeKey'])

watch(() => route.fullPath, (val) => {
    if (val.includes('IoTManage/device/detail')) {
        resetSearchForm()
        initPoints()
        if (props.activeKey == 2 && searchModel.attrCode.length && (searchModel.periodType || searchModel.periodType == 0) && searchModel.times[0] && searchModel.times[1]) {
            nextTick(() => {
                initTableData()
            })
        }
    }
})
watch(() => props.activeKey, (activeKey) => {
    initPoints()
    if (activeKey == 2 && searchModel.attrCode.length && (searchModel.periodType || searchModel.periodType == 0) && searchModel.times[0] && searchModel.times[1]) {
        initTableData()
    }
})

// 每次重新打开，重置
onActivated(() => {
    resetData()
})

const points = ref([])
const initPoints = () => {
    apiDeviceHistoryData.points(route.params.deviceId).then(({ result }) => {
        points.value = result
    })
}

const pageSizeOptions = ref(["10", "20", "50", "100"]);
const paginationProps = reactive({
    current: 1,
    pageSize: 10,
});


// 搜索栏
const searchModel = reactive({
    attrCode: [],
    periodType: null,
    times: [],
});
const pageData = ref({})

const resetSearchForm = () => {
    if (pageData[route.params.deviceId]) {
        searchModel.attrCode = pageData[route.params.deviceId].attrCode || []
        searchModel.times = pageData[route.params.deviceId].times || []
        searchModel.periodType = pageData[route.params.deviceId].periodType
        originData.value = pageData[route.params.deviceId].originData || []
        setTableColumnAndData(originData.value)
    } else {
        resetData()
    }
}

const resetData = () => {
    searchModel.attrCode = []
    searchModel.times = []
    searchModel.periodType = null
    columns.value = []
    dataSource.value = []
    originData.value = []
    allData.value = []
}


const changeAttrCode = (val) => {
    pageData[route.params.deviceId] = { ...pageData[route.params.deviceId], attrCode: val || [] }
}

// 采样周期
let currentDayDiffType = null;
const changePeriod = (value, option) => {
    searchModel.times = [];
    dates = [];
    currentDayDiffType = option?.dayDiffType ?? null;
    pageData[route.params.deviceId] = { ...pageData[route.params.deviceId], periodType: value }

};
// 日期范围
let dates = [];
const disabledDate = (current) => {
    if (!dates || dates.length === 0 || !currentDayDiffType) {
        return false;
    }
    const tooLate = dates[0] && dayjs(current).diff(dates[0], currentDayDiffType) >= 1;
    const tooEarly = dates[1] && dayjs(dates[1]).diff(current, currentDayDiffType) >= 1;
    return tooEarly || tooLate;
};
const onCalendarChange = (val) => {
    dates = val;
};
const onOpenChange = (open) => {
    if (open) {
        dates = [];
    }
};
const onChangeTimes = (val) => {
    searchModel.times = val;
    pageData[route.params.deviceId] = { ...pageData[route.params.deviceId], times: val }

};

const visible = ref(false);
const chartOptions = ref(null);

const title = ref('')
const showModal = (data) => {
    visible.value = true;
    let y = originData.value.find(e => e.code === data.key)
    title.value = data.name
    initChart(y.data)
};

const initChart = (yData) => {
    let option = {
        color: ['#00B578'],
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: 10,
            right: 10,
            bottom: 10,
            top: 30,
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: originData.value[0].data,
            }
        ],
        yAxis: {
            type: 'value',
            // name: 'm³'
        },
        series: [
            {
                type: 'line',
                data: yData,
                symbol: 'none'
            },
        ]
    };
    chartOptions.value = option

}


// 表格
const columns = ref([])
const dataSource = ref([])
const originData = ref([])
const allData = ref([])
const getColumns = (data) => {
    return data.map((e, idx) => {
        return {
            name: e.name + `${e.unit ? ('(' + e.unit + ')') : ''}`,
            dataIndex: e.code,
            key: e.code,
            numberFlag: e.numberFlag
        }
    })
}
const getData = (originData) => {
    let keys = originData.map(e => e.code)
    let data = []
    if (originData.length && originData[0].data) {
        data = originData[0].data.map((e, idx) => {
            let mapData = {}
            keys.forEach((key, keyIdx) => {
                mapData[key] = originData[keyIdx].data[idx]
            })
            return mapData
        })
    }
    console.log(data)
    return data
}

const handleSearch = () => {
    pageChange(1);
};

const initTableData = () => {
    let param = {
        bizDeviceId: route.params.deviceId,
        attrCode: searchModel.attrCode.join(','),
        periodType: searchModel.periodType
    };
    if (searchModel.times) {
        param.start = searchModel.times[0];
        param.end = searchModel.times[1];
    }
    apiDeviceHistoryData.list(param).then(({ result }) => {
        originData.value = result
        pageData[route.params.deviceId] = { ...pageData[route.params.deviceId], originData: result }
        setTableColumnAndData(result)

    });
};
const setTableColumnAndData = (result) => {
    columns.value = getColumns(result)
    if (result && result.length) {
        allData.value = getData(result)
        dataSource.value = allData.value.slice(paginationProps.pageSize * (paginationProps.current - 1), paginationProps.current * paginationProps.pageSize)
    } else {
        allData.value = []
        dataSource.value = []
    }
}
const pageChange = (pageIndex) => {
    if (searchModel.attrCode.length < 1) {
        message.warning("请选择测点");
        return
    } else if (!searchModel.periodType && searchModel.periodType != 0) {
        message.warning("请选择查询类型");
        return
    } else if (!searchModel.times[0] || !searchModel.times[1]) {
        message.warning("请选择查询时间");
        return
    }
    paginationProps.current = pageIndex;
    initTableData();
};

// 导出
const exportLoading = ref(false);
const handleExport = async () => {
    exportLoading.value = true;
    try {
        let param = {
            bizDeviceId: route.params.deviceId,
            attrCode: searchModel.attrCode.join(','),
            periodType: searchModel.periodType
        };
        if (searchModel.times) {
            param.start = searchModel.times[0];
            param.end = searchModel.times[1];
        }
        const res = await apiDeviceHistoryData.export(param);
        const { filename, blob } = res;
        downLoadFile(blob, decodeURI(filename));
        message.success("导出成功");
    } catch (error) {
        console.log(error);
    }
    exportLoading.value = false;
};


</script>
<style lang="less" scoped>
.history-data {
    &-header {
        display: flex;
        justify-content: space-between;
    }
}

.atBottom {
    position: absolute;
    bottom: 25px;
    right: 28px;
}
</style>