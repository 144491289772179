export const AlarmType = [
    {
        label: '全部事件', value: '', children: [
            { label: '通讯告警', value: 'conAlarm' },
            { label: '设备告警', value: 'devAlarm' },
            { label: '规则告警', value: 'ruleAlarm' },
            { label: '服务事件', value: 'serviceEvent' },
            { label: '其它事件', value: 'otherEvent' },
        ]
    },

]
// 采样周期
export const PeriodOptions = [
    {
        label: "原始",
        value: 0,
        dayDiffType: "week",
    },
    {
        label: "5分钟",
        value: 1,
        dayDiffType: "month",
    },
    {
        label: "10分钟",
        value: 2,
        dayDiffType: "month",
    },
    {
        label: "30分钟",
        value: 3,
        dayDiffType: "month",
    },
    {
        label: "1小时",
        value: 4,
        dayDiffType: "year",
    },
    {
        label: "8小时",
        value: 5,
        dayDiffType: "year",
    },
    {
        label: "1天",
        value: 6,
        dayDiffType: "year",
    },
];