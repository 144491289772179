<template>
    <div class='operation-monitoring'>
        <BasicCard title="设备控制服务" v-if="data.services && data.services.length">
            <span class="device-control-btn" v-for="item in data.services" :key="item.id"
                @click="openDeviceControl(item)">{{ item.functionName }}</span>
        </BasicCard>
        <BasicCard title="属性">
            <template #left>
                <span class="time" v-if="data"> {{ formatTime(data.attrUploadTime, "YYYY-MM-DD HH:mm:ss")
                    }}</span>
            </template>
            <div style="display: flex;gap: 16px;flex-wrap: wrap;">
                <NumericAttribute v-for="(item, index) in numberAttrs" :key="index" :data="item" />
                <div v-for="(item, index) in textAttrs" :key="index"
                    style="display: flex;flex-direction: column;gap: 8px;height: 132px;flex-wrap: wrap;">
                    <TextAttribute :data="item[0]" />
                    <TextAttribute v-if="item[1]" :data="item[1]" />
                </div>
            </div>

        </BasicCard>
        <BasicCard title="参数" v-if="data.properties && data.properties.length">
            <template v-slot:left>
                <a-tooltip placement="topLeft">
                    <template #title>
                        <span>刷新</span>
                    </template>
                    <span @click="initInfo"
                        style="display: inline-block;margin-left: 10px;cursor: pointer;color: #999999;"><redo-outlined /></span>
                </a-tooltip>
            </template>
            <div style="display: flex;gap: 16px;flex-wrap: wrap;">
                <div class='params' v-for="item in data.properties" :key="item.id">
                    <div class="header">{{ item.functionName }}</div>
                    <div class="params-content">
                        <span class="value">{{ item.value }}</span>
                        <span style="margin-right: 10px">{{ item.unit }}</span>
                        <span>{{ formatTime(item.updateTime, "MM-DD HH:mm:ss") }}</span>
                    </div>
                </div>
            </div>
        </BasicCard>
        <DeviceControl ref="deviceControlRef" />
    </div>
</template>

<script setup>
import { onMounted, ref, onDeactivated, watch, defineProps } from 'vue';
import NumericAttribute from './NumericAttribute.vue';
import TextAttribute from './TextAttribute.vue';
import DeviceControl from './DeviceControl.vue';
import { apiDeviceDetail } from "@/api/IoT/device.js";
import dayjs from 'dayjs';
import { useRoute } from "vue-router";
import { MQTT } from "@/utils/mqtt.js";
import { RedoOutlined } from '@ant-design/icons-vue';
import { apiUser } from "@/api/authority.js";
import { message } from "ant-design-vue";

const route = useRoute();
const data = ref({})
const numberAttrs = ref([])
const textAttrs = ref([])

const props = defineProps(['activeKey'])

watch(() => route.fullPath, (val) => {
    if (val.includes('IoTManage/device/detail')) {
        props.activeKey == 1 && initInfo()
    }
})
watch(() => props.activeKey, (activeKey) => {
    if (activeKey == 1) {
        initInfo()
    }
})
onDeactivated(() => {
    stopListen()
});

onMounted(() => {
    initInfo()
})

const initInfo = () => {
    apiDeviceDetail.operationMonitoring(route.params.deviceId).then(({ result }) => {
        data.value = result
        getNumberAndTextAttrs(result)
        let devices = result.attributes.map(e => {
            return {
                bizId: route.params.deviceId,
                code: e.identifier
            }
        })
        if (!listenMqttInstance.value) {
            getTopic(devices)
        }

    });
}
const getNumberAndTextAttrs = (data) => {
    numberAttrs.value = []
    textAttrs.value = []
    numberAttrs.value = data.attributes.filter(e => e.dataType === '02' || e.dataType === '03')
    let items = data.attributes.filter(e => (e.dataType !== '02' && e.dataType !== '03'))
    for (let index = 0; index < items.length; index = index + 2) {
        textAttrs.value.push([items[index], items[index + 1]])
    }
}
const getTopic = (data) => {
    apiDeviceDetail.deviceMonitorTopic(data).then(({ result }) => {
        startListen(result.topic)
    });
}

const deviceControlRef = ref(null);
const openDeviceControl = async (data) => {

    let flag = await checkUserControl()
    if(flag === 'forbidden'){
        message.warning('对不起，您没有指令执行权限！如需要授权，请联系管理员。')
        return
    }
    deviceControlRef.value.openModal(data, flag)
}

const checkUserControl = async ()=>{
    const { result } = await apiUser.controlCurrent()
    if(result.controlFlag === false){
        return 'forbidden'
    }
    if(result.controlFlag === true && result.passwordFlag === false){
        return 'pass'
    }
    if(result.controlFlag === true && result.passwordFlag === true){
        return 'need-passsword'
    }
}


const formatTime = (date, format) => {
    return dayjs(Number(date)).format(format)
}

const listenMqttInstance = ref(null);
const stopListen = () => {
    listenMqttInstance.value.unsubscribe();
    listenMqttInstance.value.over();
};
const startListen = (topic) => {
    listenMqttInstance.value = new MQTT(topic);
    listenMqttInstance.value.init(true);
    listenMqttInstance.value.subscribe();
    listenMqttInstance.value.listen((topic1, message) => {
        if (topic === topic1 && route.params.deviceId === message.biz_device_id) {
            data.value.attrUploadTime = message.time
            data.value.attributes.find(e => {
                let key = Object.keys(message.parameter)[0]
                if (e.identifier == key) {
                    e.times = [...e.times, formatTime(message.time, "YYYY-MM-DD HH:mm:ss")]
                    e.value = message.parameter[key]
                    e.values.push(e.value)
                    return true
                }
                return false
            })
        }
    });
};

</script>
<style lang='less' scoped>
.time {
    padding-left: 20px;
    font-size: 14px;
    font-weight: normal;
    color: #999999;
}

.device-control-btn {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin: 0 16px 0 0;
    padding: 0 16px;
    border: 1px solid #D9D9D9;
    line-height: 32px;
    width: 180px;
    height: 32px;
    color: #333333;
    font-size: 14px;
    border-radius: 2px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.016);
    cursor: pointer;

    &:hover {
        background: #F2F3F5;
    }

    &::after {
        content: '';
        position: absolute;
        right: 19px;
        top: 8px;
        width: 16px;
        height: 16px;
        background: url(../../../../assets/to-right.png) no-repeat;
        background-size: 16px 16px;
    }

}

.params {
    padding: 10px 0 10px 12px;
    width: 180px;
    height: 60px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #E5E8EF;

    .header {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 16px;
        color: #666666;
    }

    .params-content {
        font-size: 12px;
        color: #999999;
        white-space: nowrap;
    }

    .value {
        padding-right: 4px;
        color: #333333;
        font-size: 16px;
        font-weight: 500;

    }

}
</style>