<!-- eslint-disable no-unreachable -->
<template>
    <a-modal v-model:visible="visible" title="开启设备" @ok="handleOk">
        <template v-if="data.identifier === 'GeneralWritePoints'">
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" v-if="data && data.functionParameter">
                <a-form-item :label="data.functionParameter[0].name">
                    <a-select v-model:value="data.general.writablePoint" placeholder="请选择" @change="changePoint"
                        style="width: 100%;">
                        <a-select-option v-for="item in data.functionParameter[0].valueDescription" :key="item.key"
                            :value="item.key">{{ item.value }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item :label="data.functionParameter[1].name">
                    <a-input v-if="data.general && data.general.writablePointValueOption.dataType == '01'"
                        v-model:value="data.general.writablePointValue"
                        :maxlength="data.general.writablePointValueOption.valueDescription ? Number(data.general.writablePointValueOption.valueDescription[0].value) : null"
                        style="width: 100%;"></a-input>
                    <a-input-number style="width: 100%;"
                        v-else-if="data.general.writablePointValueOption.dataType == '02' || data.general.writablePointValueOption.dataType == '03'"
                        v-model:value="data.general.writablePointValue"
                        :min="findValue(data.general.writablePointValueOption.valueDescription, 'MIN', Number)"
                        :max="findValue(data.general.writablePointValueOption.valueDescription, 'MAX', Number)" />
                    <a-select v-else v-model:value="data.general.writablePointValue" placeholder="请选择"
                        @change="changePointValue" style="width: 100%;">
                        <a-select-option v-for="item in data.general.writablePointValueOption.valueDescription"
                            :key="item.key" :value="item.key">{{ item.value }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </template>
        <template v-else>
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                <a-form-item v-for="item in data.functionParameter" :key="item.identifier" :label="item.name">
                    <a-input v-if="item.dataType == '01'" v-model:value="item.value" style="width: 100%;"
                        :maxlength="Number(item.valueDescription[0].value)" :disabled="item.checked"></a-input>
                    <a-input-number style="width: 100%;" v-else-if="item.dataType == '02' || item.dataType == '03'"
                        v-model:value="item.value" :min="findValue(item.valueDescription, 'MIN', Number)"
                        :max="findValue(item.valueDescription, 'MAX', Number)" :disabled="item.checked" />
                    <a-select v-else style="width: 100%;" v-model:value="item.value" placeholder="请选择"
                        :disabled="item.checked">
                        <a-select-option v-for="item in item.valueDescription" :key="item.key" :value="item.key">{{
        item.value }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </template>
        <template #footer>
            <a-button key="back" @click="visible = false">取 消</a-button>
            <a-button key="submit" type="primary" @click="doAction">下 发</a-button>
        </template>
    </a-modal>
    <a-modal width="520px" v-if="innerVisible" title="指令下发提醒" v-model:visible="innerVisible" append-to-body>
        <div v-if="data.identifier === 'GeneralWritePoints'">即将执行开启设备服务（<span style="margin-right: 10px;">{{
        data.functionParameter[0].name }}：{{ data.general.writablePointValueOption.name }}</span>
            <span>{{ data.functionParameter[1].name }}：{{ (data.general.writablePointValueOption.dataType == '04' ||
        data.general.writablePointValueOption.dataType ==
        '05') ? data.general.writablePointValueLabel : data.general.writablePointValue
                }}</span>），请输入操作密码执行指令！
            <a-input v-model:value="password" size="large" style="margin: 10px auto;width: 100%;" />
        </div>
        <div v-else>即将执行开启设备服务（<span v-for="(item, idx) in data.functionParameter" :key="idx"
                style="margin-right: 10px;">{{
        item.name }}：<span v-if="item.value != undefined">{{ getValueLabel(item) }}</span></span>
            ），请输入操作密码执行指令！
            <a-input v-model:value="password" size="large" style="margin: 10px auto;width: 100%;" />
        </div>

        <template #footer>
            <a-button key="back" @click="innerVisible = false">取 消</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="submit">确 认</a-button>
        </template>
    </a-modal>
    <a-modal width="520px" title="开启设备" v-model:visible="visible3" append-to-body :maskClosable="false"
        :closable="false" cancel-text="">
        <div><span class="time">{{ time1 }}</span><span>指令下发</span></div>
        <div v-if="doActionTip"><span class="time">{{ time2 }}</span><span>{{ doActionTip }}</span></div>
        <template #footer>
            <a-button key="back" :disabled="!doActionFinish" @click="closeModal">关 闭</a-button>
        </template>
    </a-modal>
</template>

<script setup>
import md5 from "crypto-js/md5";
import { apiDeviceDetail } from "@/api/IoT/device.js";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";
import { ref, defineExpose, watch } from 'vue';
import dayjs from "dayjs";
const route = useRoute();
const visible = ref(false);
const innerVisible = ref(false);
const visible3 = ref(false);
const password = ref('');
const loading = ref(false);

const data = ref({})
const actionFlag = ref('')
const openModal = (item, flag) => {
    visible.value = true;
    item.general = {
        writablePoint: '',
        writablePointValue: '',
        writablePointValueOption: {},
        writablePointValueLabel: ''
    }
    actionFlag.value = flag
    data.value = item
}

watch(()=>innerVisible.value, ()=>{
    password.value = ''
})

const handleOk = () => {
    visible.value = false;
};
const changePoint = (identifier) => {
    let { controlPointDesc, general } = data.value
    general.writablePointValueOption = controlPointDesc.find(e => e.identifier === identifier)
    general.writablePointValue = ''
};
const changePointValue = (identifier) => {
    let { general } = data.value
    let item = general.writablePointValueOption.valueDescription.find(e => e.key === identifier)
    general.writablePointValueLabel = item.value
};

const findValue = (arr, type, formatType) => {
    let item = arr.find(e => e.key === type)
    return formatType(item?.value)
}
const getValueLabel = (data) => {
    if (data.dataType == '04' || data.dataType == '05') {
        let item = data.valueDescription.find(e => {
            return e.key === data.value
        })
        return item?.value || data.value
    }
    return data.value
}

const doAction = async () => {
    visible.value = false
    if(actionFlag.value === 'need-passsword'){
        innerVisible.value = true
    }
    if(actionFlag.value === 'pass'){
        submitAction()
    }   
}



const doActionTip = ref('')
const doActionFinish = ref(false)
const time1 = ref('')
const time2 = ref('')
const submit = () => {
    if (!password.value.length) {
        message.error('请输入密码')
        return
    }
    submitAction(password.value)
}
const submitAction = (password)=>{

    let m = {
        bizDeviceId: route.params.deviceId,
        identifier: data.value.identifier,
    }
    if(password){
        m.password = md5(password).toString()
    }
    if (data.value.identifier === 'GeneralWritePoints') {
        m.functionParameters = [{
            identifier: data.value.functionParameter[0].identifier,
            value: data.value.general.writablePoint,
        }, {
            identifier: data.value.functionParameter[1].identifier,
            value: data.value.general.writablePointValue
        }]
    } else {
        m.functionParameters = data.value.functionParameter.map(e => {
            return {
                identifier: e.identifier,
                value: e.value
            }
        })
    }
    time1.value = dayjs().format('HH:mm:ss')
    let tmr = setTimeout(() => {
        doActionFinish.value = true
    }, 60000)
    loading.value = true
    apiDeviceDetail.deviceControl(m).then((res) => {
        if (res.errorCode == '密码错误') {
            return
        }
        innerVisible.value = false
        visible3.value = true
        doActionTip.value = res.errorCode == 200 ? '指令执行成功！' : '指令执行失败！'
        password.value = ''
        data.value.general = {
            writablePoint: '',
            writablePointValue: '',
            writablePointValueOption: {},
            writablePointValueLabel: ''
        }
        data.value.functionParameter.forEach(e => e.value = '')
    }).finally(() => {
        time2.value = dayjs().format('HH:mm:ss')
        doActionFinish.value = true
        loading.value = false
        clearTimeout(tmr)
    })
}

const closeModal = () => {
    doActionTip.value = ''
    doActionFinish.value = false
    visible3.value = false
}


defineExpose({
    openModal,
});
</script>
<style lang='less' scoped>
.time {
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    line-height: 30px;
    color: #999999;
    white-space: nowrap;
}
</style>