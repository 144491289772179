<template>
    <div class='numeric-attribute'>
        <div class="header">
            <span>{{ data.functionName }}</span>
            <span>
                <span class="value">{{ data.value }}</span>
                <span>{{ data.unit }}</span>
            </span>
        </div>
        <div style="margin-top: 10px;">
            <BaseChart style="margin: 0 8px;" v-if="chartOptions" width="100%" height="80px" :options="chartOptions">
            </BaseChart>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, watch } from 'vue';
import { onMounted } from 'vue';
import * as echarts from "echarts";

const props = defineProps({
    data: {}
})

const chartOptions = ref(null);

onMounted(() => {
    initChart()
})
watch(() => props.data.times, () => {
    initChart()
})
const initChart = () => {
    let option = {
        color: ['#147AD6'],
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: 0,
            right: 10,
            bottom: 20,
            top: 10,
        },
        xAxis: [
            {
                type: 'category',
                data: props.data.times,
                show: false

            }
        ],
        yAxis: {
            type: 'value',
            show: false,


        },
        series: [
            {
                type: 'line',
                data: props.data.values,
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' ' + props.data.unit;
                    }
                },
                smooth: true,
                showSymbol: false,
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(20, 122, 214, 0.3)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(255, 255, 255, 0.3)'
                        }
                    ])
                }
            }
        ]
    };
    chartOptions.value = option

}

</script>
<style lang='less' scoped>
.numeric-attribute {
    padding: 12px;
    width: 180px;
    height: 128px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #E5E8EF;

    .header {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 16px;
        color: #666666;
    }

    .value {
        padding-right: 4px;
        color: #1D2129;
        font-size: 16px;
        font-weight: 500;
    }

}
</style>