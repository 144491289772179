<template>
    <div class="history-event">
        <div class="history-event-header">
            <a-form layout="inline" :model="searchModel" style="margin-bottom: 20px;">
                <a-form-item label="查询类型">
                    <a-tree-select v-model:value="searchModel.alarmType" show-search style="min-width: 180px"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" allow-clear tree-default-expand-all
                        :tree-data="AlarmType" @change="changeAlarmType">
                    </a-tree-select>
                </a-form-item>
                <a-form-item label="查询时间">
                    <a-range-picker v-model:value="searchModel.times" :disabled-date="disabledDate"
                        @change="onChangeTimes" :show-time="{
                            defaultValue: [
                                dayjs('00:00:00', 'HH:mm:ss'),
                                dayjs('23:59:59', 'HH:mm:ss'),
                            ]
                        }" valueFormat="YYYY-MM-DD HH:mm:ss" :allowClear="false" />
                </a-form-item>
            </a-form>
            <div>
                <a-button type="primary" @click="handleSearch" style="margin-right: 8px;">查询</a-button>
                <a-button @click="handleExport" :loading="exportLoading">导出</a-button>
            </div>
        </div>
        <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
            <template #slotStatus="{ row }">
                <span>{{ row.slotConfirm ? '已确认' : '未确认' }}</span>
            </template>
            <template #slotAlarmLevel="{ row }">
                <a-tag color="blue" v-if="row.alarmLevel == '00'">{{ row.alarmLevelName }}</a-tag>
                <a-tag color="green" v-else-if="row.alarmLevel == '01'">{{ row.alarmLevelName }}</a-tag>
                <a-tag color="orange" v-else-if="row.alarmLevel == '02'">{{ row.alarmLevelName }}</a-tag>
                <a-tag color="red" v-else>{{ row.alarmLevelName }}</a-tag>
            </template>
        </BasicTable>
    </div>
</template>
<script setup>
import { ref, reactive, watch, defineProps, nextTick, onActivated } from "vue";
import { BasicTable } from "@/components/basic/table";
import { AlarmType } from "@/config";
import dayjs from "dayjs";
import { apiDeviceHistoryEvent } from "@/api/IoT/device.js";
import { downLoadFile } from "@/utils/excel";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";

const route = useRoute();


const searchModel = reactive({
    alarmType: '',
    times: [dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss")]
});
const allSearchModel = ref({})

const props = defineProps(['activeKey'])

// 每次重新打开，重置
onActivated(() => {
    searchModel.alarmType = '';
    searchModel.times = [dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss")];
    allSearchModel[route.params.deviceId] = searchModel
})

watch(() => route.fullPath, (val) => {
    if (val.includes('IoTManage/device/detail')) {
        resetSearchForm()
        if (props.activeKey == 3) {
            nextTick(() => {
                initTableData()
            })
        }
    }
})
watch(() => props.activeKey, (activeKey) => {
    if (activeKey == 3) {
        initTableData()
    }
})

const resetSearchForm = () => {
    if (allSearchModel[route.params.deviceId]) {
        searchModel.alarmType = allSearchModel[route.params.deviceId].alarmType
        searchModel.times = allSearchModel[route.params.deviceId].times
    } else {
        searchModel.alarmType = '';
        searchModel.times = [dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss")];
    }
}

const changeAlarmType = (val) => {
    allSearchModel[route.params.deviceId] = { ...allSearchModel[route.params.deviceId], alarmType: val }
}

// 日期范围
const disabledDate = (current) => {
    if (!searchModel.times || searchModel.times.length === 0) {
        return false;
    }
    const tooLate = searchModel.times[0] && dayjs(current).diff(searchModel.times[0], "day") > 30;
    const tooEarly = searchModel.times[1] && dayjs(searchModel.times[1]).diff(current, "day") > 30;
    return tooEarly || tooLate;
};
const onChangeTimes = (val) => {
    searchModel.times = val;
    allSearchModel[route.params.deviceId] = { ...allSearchModel[route.params.deviceId], times: val }
};

const handleSearch = () => {
    tableRef.value.paginationProps.current = 1;
    initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
    showIndex: false,
    showSelect: false,
    pagination: true,
    isPageBottom: true,
    remote: false,
    loading: false,
    scroll: {
        scrollToFirstRowOnChange: true,
        y: "calc(100vh - 280px)",
    },
    columns: [
        {
            title: "时间",
            type: "text",
            key: "eventTimeStr",
        },
        {
            title: "事件ID",
            type: "text",
            key: "eventId",
        },
        {
            title: "事件类型",
            type: "text",
            key: "alarmTypeName",
        },
        {
            title: "事件码",
            type: "text",
            key: "alarmCode",
        },
        {
            title: "事件内容",
            type: "text",
            key: "alarmDesc",
        },
        {
            title: "事件等级",
            type: "customize",
            slotName: "slotAlarmLevel",
        },
        {
            title: "事件状态",
            type: "text",
            key: "alarmStatusName",
        },
        {
            title: "确认状态",
            type: "customize",
            slotName: "slotConfirm",
        },
    ],
});
const initTableData = async () => {
    const { current, pageSize } = tableRef.value.paginationProps;
    const params = {
        pageNo: current,
        pageSize,
        alarmType: searchModel.alarmType,
        startTime: searchModel.times[0],
        endTime: searchModel.times[1],
        bizDeviceId: route.params.deviceId
    };
    tableConfig.loading = true;
    try {
        tableRef.value.dataSourceRef = [];
        const res = await apiDeviceHistoryEvent.list(params);
        const { records, total } = res?.result ?? {};
        tableConfig.loading = false;
        tableRef.value.dataSourceRef = records;
        tableRef.value.paginationProps.total = total;
    } catch (error) {
        tableConfig.loading = false;
        console.log(error);
    }
};
const pageChange = (pageIndex) => {
    tableRef.value.paginationProps.current = pageIndex;
    initTableData();
};

// 导出
const exportLoading = ref(false);
const handleExport = async () => {
    exportLoading.value = true;
    try {
        let param = {
            bizDeviceId: route.params.deviceId,
            alarmType: searchModel.alarmType,
        };
        if (searchModel.times) {
            param.startTime = searchModel.times[0];
            param.endTime = searchModel.times[1];
        }
        const res = await apiDeviceHistoryEvent.export(param);
        const { filename, blob } = res;
        downLoadFile(blob, decodeURI(filename));
        message.success("导出成功");
    } catch (error) {
        console.log(error);
    }
    exportLoading.value = false;
};


</script>
<style lang="less" scoped>
.history-event {
    &-header {
        display: flex;
        justify-content: space-between;
    }
}
</style>