import http from '../../utils/http'

// 设备详情
export const apiDeviceDetail = {
    detail(bizDeviceId) {
        return http.request({
            url: `/cemp-bms/device-manager/detail`,
            method: 'get',
            params: {
                bizDeviceId
            }
        })
    },
    editDetail(id) {
        return http.request({
            url: `/cemp-bms/device-monitor/info`,
            method: 'get',
            params: {
                id
            }
        })
    },
    operationMonitoring(bizDeviceId) {
        return http.request({
            url: '/cemp-bms/device-manager/status-monitor',
            method: 'get',
            params: {
                bizDeviceId
            }
        })
    },
    deviceControl(data) {
        return http.request({
            url: '/cemp-bms/device-manager/service-control',
            method: 'post',
            data
        })
    },
    deviceMonitorTopic(data) {
        return http.request({
            url: `/cemp-monitor/topic/get`,
            method: 'post',
            data
        })
    },


}
// 设备数据
export const apiDeviceHistoryData = {
    points(bizDeviceId) {
        return http.request({
            url: `/cemp-bms/device-manager/device-attributes/list`,
            method: 'get',
            params: {
                bizDeviceId
            }
        })
    },
    list(data) {
        return http.request({
            url: '/cemp-bms/device-manager/device-attributes/history',
            method: 'post',
            data
        })
    },
    export(data) {
        return http.request({
            url: '/cemp-bms/device-manager/device-attributes/history/export',
            method: 'post',
            responseType: "blob",
            data
        })
    }
}
// 设备事件
export const apiDeviceHistoryEvent = {
    list(data) {
        return http.request({
            url: '/cemp-bms/device-manager/device-events/history',
            method: 'post',
            data
        })
    },
    export(data) {
        return http.request({
            url: '/cemp-bms/device-manager/device-events/history/export',
            method: 'post',
            responseType: "blob",
            data
        })
    }


}