<template>
  <div class="common-wrapper" id="commonwrapper">
    <aside class="aside">
      <div class="aside-header">
        <div class="deliver">
          <a-tooltip>
            <template #title>编辑</template>
            <form-outlined @click="showModal" />
          </a-tooltip>
        </div>
        <auth-image :src="data.image" size="100px" />
        <div class="name">
          <a-typography-text v-if="data.name" :ellipsis="{ tooltip: data.name }"
            :content="data.name"></a-typography-text>
        </div>
        <div class="id">{{ data.bizDeviceId }}（系统ID）</div>
        <div class="desc">
          <a-typography-paragraph v-if="data.deviceDesc" :ellipsis="{ rows: 3, tooltip: data.description }"
            :content="data.deviceDesc" />
        </div>
      </div>
      <div class="aside-content">
        <div class="status">
          <div :class="['switch-btn', { 'switch-btn-active': data.cst === 1 }]">
            <check-outlined v-if="data.cst === 1" />
            <pause-circle-outlined v-else />
            <span style="margin-left: 15px">通讯状态：{{ data.cst == 1 ? "在线" : "离线" }}</span>
          </div>
        </div>

        <div class="col1">
          <div class="attr-key">产品品类</div>
          <div class="attr-value">{{ data.categoryName }}</div>
        </div>
        <div class="col1">
          <div class="attr-key">产品名称</div>
          <div class="attr-value">{{ data.productName }}</div>
        </div>
        <div class="col1">
          <div class="attr-key">产品型号</div>
          <div class="attr-value">{{ data.model }}</div>
        </div>
        <div class="col1">
          <div class="attr-key">设备ID（外）</div>
          <div class="attr-value">{{ data.sourceDeviceId }}</div>
        </div>
        <div class="col1">
          <div class="attr-key">设备编码</div>
          <div class="attr-value">{{ data.code }}</div>
        </div>
        <div class="col1"></div>
        <div class="col2">
          <div class="attr-key">项目名称</div>
          <div class="attr-value">{{ data.projectName }}</div>
        </div>
        <div class="col2">
          <div class="attr-key">设备位置</div>
          <div class="attr-value">{{ data.locationDesc }}</div>
        </div>
      </div>
    </aside>
    <main class="content" v-if="showTab">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="运行监控">
          <OperationMonitoring :activeKey="activeKey" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="历史数据">
          <HistoryData :activeKey="activeKey" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="历史事件">
          <HistoryEvent :activeKey="activeKey" />
        </a-tab-pane>
      </a-tabs>
    </main>
    <DeviceEditModal v-if="modalVisible" v-model:visible="modalVisible" :productId="currentRow.productId"
      :productName="data.name" :detail="currentRow" @success="editSuccess"></DeviceEditModal>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { FormOutlined } from "@ant-design/icons-vue";

import authImage from "@/components/basic/authImage/index.vue";
import OperationMonitoring from "./components/OperationMonitoring.vue";
import HistoryData from "./components/HistoryData.vue";
import HistoryEvent from "./components/HistoryEvent.vue";
import DeviceEditModal from "./components/DeviceEditModal.vue";
import { apiDeviceDetail } from "@/api/IoT/device.js";
import { useRoute } from "vue-router";
const route = useRoute();

const data = ref({});
const activeKey = ref("1");
const showTab = ref(true);
const currentRow = ref({});
watch(() => route.fullPath, (val) => {
  if (val.includes('IoTManage/device/detail')) {
    initBaseInfo()
  }
})

onMounted(() => {
  initBaseInfo();
});

const initBaseInfo = () => {
  data.value = {}
  apiDeviceDetail.detail(route.params.deviceId).then(({ result }) => {
    data.value = result;
  });
};

const modalVisible = ref(false);
const showModal = () => {
  apiDeviceDetail.editDetail(data.value.id).then(({ result }) => {
    currentRow.value = result;
    modalVisible.value = true;
  });
};
const editSuccess = async () => {
  initBaseInfo();
};
</script>
<style lang="less" scoped>
.common-wrapper {
  position: relative;
  background: none;
  padding: 0;
  height: 100%;
}

.aside {
  position: absolute;
  left: 0;
  top: 0;
  flex-direction: column;
  width: 240px;
  height: 100%;
  background-color: #fff;
  font-size: 14px;
  overflow: auto;

  &-header {
    padding: 18px 20px 20px;

    .deliver {
      color: #0256ff;
      font-size: 12px;
      text-align: right;
      height: 14px;
    }

    .name {
      padding-top: 20px;
      font-weight: bold;
      color: #38404c;
    }

    .id {
      color: #999;
      line-height: 38px;
    }

    .desc {
      font-size: 14px;
      line-height: 16px;
    }

    :deep .ant-typography {
      margin: 0;
      color: #666;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e5e6e8;

    .status {
      display: flex;
      align-items: center;
      height: 40px;
      color: #fff;
      width: 100%;

      .switch-btn {
        position: relative;
        display: inline-block;
        height: 100%;
        width: 100%;
        line-height: 40px;
        background-color: #d9d9d9;
        text-align: center;
        color: #fff;

        transition: width 0.5s;

        .tip {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: none;
          background-color: #799aff;
        }
      }

      .switch-btn:hover {
        .tip {
          display: block;
        }
      }

      .switch-btn-active {
        background-color: #00b578;
      }
    }

    .col1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 60px;
      border-top: 1px solid #e5e6e8;
      text-align: center;
      width: 50%;
    }

    .attr-key {
      color: #999;
      font-size: 12px;
      line-height: 20px;
    }

    .attr-value {
      color: #38404c;
      font-size: 14px;
      line-height: 24px;
    }

    .col2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 60px;
      border-top: 1px solid #e5e6e8;
      text-align: center;
      width: 100%;
    }

    .col1:nth-child(2n) {
      border-right: 1px solid #e5e6e8;
    }
  }
}

.content {
  position: relative;
  margin-left: 248px;
  padding: 0 20px 20px;
  height: 100%;
  background-color: #fff;
  overflow: auto;
}
</style>
